import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
// import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();

  // removed sentry due to privacy concerns (new licence on 01/2024)

  //   Sentry.init({
  //     dsn: "https://b0e5911d497f43c29c5525a2426d093d@o331765.ingest.sentry.io/4505085306994688",
  //     integrations: [
  //       new Sentry.BrowserTracing({
  //         routingInstrumentation: Sentry.routingInstrumentation,
  //       }),
  //       new Sentry.Replay(),
  //       new Sentry.Integrations.Breadcrumbs({
  //         console: false,
  //       }),
  //     ],
  //     // Performance Monitoring
  //     tracesSampleRate: 0.8, // 1.0 -> Capture 100% of the transactions, reduce in production!
  //     // Session Replay
  //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
